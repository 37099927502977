.projects-page {
  margin-top: 128px;
}

.projects-body {
  margin: 0 auto;
  position: relative;
  width: 100%;
}

#project-comp-title {
  margin-bottom: 32px;
}

.project-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 75%;
  margin: 0 auto;
  margin-bottom: 32px;
  display: flex;
  background-color: aliceblue;
  border-radius: 8px;
  box-shadow: 4px 4px 18px 2px rgba(0, 0, 0, .5);
  transition: transform 500ms;
}


.project-image {
  width: 50%;
  min-width: 30%;
  padding: 10px;
  object-fit: contain;
}

.project-iframe {
  min-width: 50%;
  min-height: 400px;
  padding: 10px;
}

.bandcamp-iframe {
  max-width: 350px;
  min-width: 350px;
}

.project-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 50px;
}

.project-description {
  white-space: pre-line;
  text-align: justify
}

.project-info>h1 {
  font-size: 3rem;

}

.project-btns {
  display: flex;
  justify-content: space-around;
  width: 75%
}

.project-btn {
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: rgb(46, 44, 42);
  color: aliceblue;
  font-size: 30px;
  transition: 300ms;
  padding: 20px;
  height: fit-content;
}

.project-btn:hover {
  border: 1px solid rgb(46, 44, 42);
  text-decoration: none;
  cursor: pointer;
}

.project-btn:hover .project-btn-text {
  background: linear-gradient(90deg, red, orange, yellow, green, blue, purple);
  animation: animate 5s linear infinite;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-decoration: none;
  transition: 300ms;
}

.project-btn-text {
  padding: 0;
  margin: 0;
}

.projects-body>h3 {
  text-align: center;
  font-size: 48px;
  margin: 0;
}

.projects-body h1 {
  text-align: center;
}

.tools {
  font-style: italic;
  text-align: center;
}

.music-choices {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 16px;
  transform: translateY(-500px);
  transition: 300ms;
  flex-wrap: wrap;
}

.music-choice {
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0;
}

.music-choice:hover::after {
  width: 80%;
  transition: width 500ms;
}

.music-choice::after {
  content: " ";
  height: 4px;
  width: 0px;
  text-align: center;
  background-color: white;
}

.selected-choice::after {
  content: " ";
  height: 4px;
  width: 0px;
  text-align: center;
  background-color: white;
  width: 80%;
}

.music-choice-btn {
  border: none;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, .3);
  padding: 8px 8px;
  font-family: "Poppins", serif;
  margin: 8px;
}

@media screen and (max-width: 1380px) {

  .project-card {
    flex-direction: column;
    position: relative;
  }

  .project-title {
    position: absolute;
    top: 10px;
    margin: 0;
  }

  .project-image,
  .project-iframe {
    margin: 15px auto;
    margin-top: 100px;
    order: 1;
  }

  .project-info {
    order: 2;
  }

  .project-iframe {
    min-height: 300px;
    width: 90%;
  }

  .bandcamp-iframe {
    min-height: 350px;
  }


}

@media screen and (max-width: 700px) {
  .project-btns {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .project-btn {
    margin: 10px;
  }

  .project-info {
    text-align: justify;
  }

  .project-image,
  .project-iframe {
    margin: 15px auto;
    margin-top: 150px;
  }

  .project-card {
    width: 95vw;
  }

  #about-bio {
    width: 95vw
  }


}