/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

@font-face {
  font-family: "Poppins";
  src: url("./styles/Poppins/Poppins-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url("./styles/Poppins/Poppins-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Poppins";
  src: url("./styles/Poppins/Poppins-Light.ttf");
  font-weight: 200;
}

body {
  margin: 0;
  font-family: "Poppins", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}