header {
    width: 100vw;
    height: 100px;
    position: absolute;
}

* {
    /* border: 1px solid red; */
    box-sizing: border-box;
}

.header-content {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
    z-index: 1;
}

.header-black {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: inset 0 0 120px 12px black;
    z-index: 1;
}

nav {
    display: flex;
    justify-content: space-around;
    padding: .5rem;
    width: 40%;
}

.name {
    margin: 28px 28px 1.8rem;
}

.nav-btn {
    background-color: black;
    font-family: 'Poppins', serif;
    color: white;
    text-shadow: 0 0 1px inset black;
    font-size: large;
    font-weight: 700;
    border: none;
    border-radius: 10px;
    padding: 0 30px;
    height: 50px;
    display: flex;
    align-items: center;
    transition: 300ms;
    box-shadow: inset 0px 0px 100px rgba(255, 0, 0, 0.23);
}

.selected-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.selected-btn::after {
    content: " ";
    height: 4px;
    text-align: center;
    background-color: white;
    width: 80%;
}

.nav-btn:hover {
    cursor: pointer;
    transition: 300ms;
    color: linear-gradient(90deg, red, orange, yellow, green, blue, purple);
    animation: animate 5s linear infinite;
}

.about-btn {
    background-image: url("https://res.cloudinary.com/dsvcyich1/image/upload/v1693442901/flower1_y5mj8j.png");
}

.projects-btn {
    background-image: url("https://res.cloudinary.com/dsvcyich1/image/upload/v1693442803/flower6_gjhcee.png");
}

.music-btn {
    background-image: url("https://res.cloudinary.com/dsvcyich1/image/upload/v1693442901/flower5_kp7nof.png");
    background-size: cover;
}


.Link {
    text-decoration: none;
}


.hamburger {
    display: none;
    width: 50px;
    height: auto;
    margin: 30px auto;
}

.music-btn-ctr {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.toggle-anim {
    border-radius: 10px;
    height: 20px;
    position: relative;
    margin-top: 8px;
    width: 50px;
    overflow: hidden;
    animation: bganimate2 20s linear infinite;
    outline: 2px solid gray;
}

.toggle-anim-bg {
    position: absolute;
    height: 100%;
    transition: 300ms;
    animation: bganimate1 20s linear infinite;
}

.toggle-anim-ball {
    position: absolute;
    background: linear-gradient(45deg, #e8f59e, #bcc3ff, #ffafaf);
    border: 1px solid gray;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    transition: 300ms;
}




#hamburger-div {
    width: 50px;
    height: 75px;
    margin: 0 auto;
    z-index: 1;
}

.dropdown-hidden {
    display: none;
}

.dropdown-visible {
    display: block;
}

/* The container <div> - needed to position the dropdown content */
.dropbtn {
    position: relative;
    display: none;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    position: absolute;
    left: -56px;
    background: rgba(0, 0, 0, 0.8);
    min-width: 160px;
    min-height: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 15%;
}

/* Links inside the dropdown */
.burger-link {
    display: block;
    cursor: pointer;
}

.burger-link-text {
    display: block;
    margin: 0.5em;
    text-align: center;
    color: transparent;
    font-size: 24px;
    font-weight: bold;

}

@media screen and (max-width: 1000px) {
    .hamburger {
        display: block;
    }

    .dropbtn {
        display: block;
    }
}