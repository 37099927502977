* {
  /* border: 1px solid red; */
  text-decoration: none;
  box-sizing: border-box;
}

.App {
  min-height: 100vh;
  font-family: "Poppins", serif;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.white {
  color: white;
}

.nopacity {
  opacity: 0;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.the404 {
  margin: 100px auto;
  width: 100%;
  padding: 10px;
  height: 70vh;
  text-align: center;
  color: white;
  z-index: 100;
  position: absolute;
}

.the404>img {
  height: 70%;
  width: auto;
  margin: 0;
}

.loading-h1 {
  color: white;
  text-align: center;
  margin-top: 160px;
}



.bg-color {
  height: 100%;
  width: 100%;
  background: linear-gradient(#d60000, #02ffb3);
  animation: bganimate1 30s linear infinite;
  opacity: 0.5;
  position: absolute;
}

.name {
  font-size: 5rem;
  color: transparent;
  width: fit-content;
  background-color: white;
  background-size: contain;
  background-clip: text;
}

.about-name {
  display: none;
}

.about-main {
  display: flex;
  padding: 2rem;
  align-items: center;
  margin-top: 90px;
  overflow: hidden;
  justify-content: space-between;
  transition: 1000ms ease-in-out
}

#about-bio {
  width: 50%;
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  background-color: aliceblue;
  z-index: 1;
  box-shadow: 4px 4px 18px 2px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  transform: translateX(1300px);
  transition: 500ms;
}

.bio-text {
  font-size: 1rem;
}

.home-main {
  height: 100%;
  min-height: 500px;
  min-width: 100vw;
  display: flex;
  position: absolute;
  z-index: 2;
  overflow: hidden;
}

.chorale {
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: 1;
}

.mobile-landing-bg {
  position: absolute;
  width: 100vw;
  height: 110vh;
  object-fit: cover;
  z-index: 1;
  top: -56px;
  display: none
}

#welcome {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  text-shadow: 0 0 2px black;
  font-size: 100px;
  z-index: 3;
  margin: 0;
  color: white;
}

.welcome-btns {
  display: flex;
  justify-content: space-around;
  width: 75%;
  bottom: 35%;
  z-index: 3;
  margin: auto auto;
}

.welcome-btn {
  height: 17vw;
  width: 27vw;
  min-width: 250px;
  min-height: 160px;
  font-size: 48px;
  color: rgb(255, 255, 255);
  border-radius: 6px;
  border: none;
  cursor: pointer;
  opacity: .9;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, .5);
  background: url("../public/images/Twirl 1.png");
  background-size: 100% 100%;
}

.welcome-btn:hover .welcome-btn-text {
  color: transparent;
  background: linear-gradient(90deg, red, orange, yellow, green, blue, purple);
  animation: animate 5s linear infinite;
  background-clip: text;
  -webkit-background-clip: text;
  text-shadow: none;
  transition: 300ms;
}

.welcome-btn-text {
  opacity: 1;
  width: fit-content;
  margin: 0 auto;
  font-weight: 900;
  text-shadow: 0 0 2px black;
}

.code-enter {
  left: 20%;
}

.music-enter {
  right: 20%;
  transform: rotateY(180deg);

}

.music-enter>p {
  transform: rotateY(180deg);
}

h2 {
  font-size: 48px;
}

.gif-ctr {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  padding: 0;
  margin: 0;
  transition: margin-left 300ms;
}

.gif {
  width: 85%;
  height: auto;
  object-fit: cover;
  border-radius: 800px;
  transform: translateX(-1000px);
  box-shadow: 4px 4px 18px 2px rgba(0, 0, 0, 0.5);
  transition: 500ms;
}

.headshot {
  border-radius: 50%;
  margin-right: 5%;
}

.skills-ctr {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
}

.skill-card {
  display: inline;
  min-width: 100px;
  min-height: 85px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin: 10px;
  padding: 2px;
}

.skill-logo {
  height: 40px;
  width: auto;
  margin: auto;
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0));
  transition: 500ms;
}

.shrunk {
  height: 0px;
  width: 0px;
}

.skill-card:hover h4 {
  box-shadow: 0px 1px black;
  transition: 500ms;
}

.skill-card:hover .skill-logo {
  transition: 500ms;
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.5));
  animation: rotation 1s linear infinite;
}

.skill-card>h4 {
  box-shadow: none;
  display: inline;
  color: black;
  width: fit-content;
  margin: 8px auto;
  transition: 500ms;
}

.far-left {
  margin-left: -700px;
}

.far-right {
  margin-left: 2000px;
}

.welcome-bg {
  position: absolute;
  top: 0;
  height: 100vh;
  min-width: 100vw;
  background-size: cover;
  z-index: 3;
  transition: 500ms;
  background: linear-gradient(217deg,
      rgba(255, 0, 0, 1),
      rgb(98, 0, 255) 70.71%);
  animation: animate 18s infinite;
  opacity: 1;
}

.welcome-msg {
  text-align: center;
  margin: 36vh 2vw;
  color: white;
  font-size: 72px;
}

.welcome-msg>span {
  font-size: 42px;
  font-style: italic;
}

.link {
  background: linear-gradient(90deg, red, orange, yellow, green, blue, purple);
  animation: animate 5s linear infinite;
  background-clip: text;
  -webkit-background-clip: text;
  transition: 300ms;
  color: white;
}

.works {
  color: white;
}

.works:hover {
  color: transparent;
  text-decoration: none;
  background: linear-gradient(90deg, red, orange, yellow, green, blue, purple);
  animation: 5s linear infinite;
  background-clip: text;
  -webkit-background-clip: text;
}

.link:hover {
  color: transparent;
  transition: 400ms;
}

.here {
  background: linear-gradient(90deg, red, orange, yellow, green, blue, purple);
  animation: animate 10s linear infinite;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-decoration: none;
}


@keyframes animate {

  0%,
  100% {
    filter: hue-rotate(0deg);
  }

  50% {
    filter: hue-rotate(360deg);
  }
}

.intro-rotation {
  animation: rotation .5s;
}

.flip {
  /* animation: flip .5s ease-in-out; */
  animation: flip .5s;
}

@keyframes rotation {
  0% {
    rotate: 0px;
  }

  100% {
    rotate: 359deg;
  }
}

@keyframes flip {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(359deg);
  }
}

@keyframes bganimate1 {

  0%,
  100% {
    background: rgb(132, 0, 255);
  }

  50% {
    background: rgb(0, 47, 255);
  }
}

@keyframes bganimate2 {

  0%,
  100% {
    background: rgb(255, 0, 0);
  }

  50% {
    background: rgb(81, 255, 0);
  }
}




@media screen and (max-width: 1000px) {
  .about-main {
    flex-direction: column;
    margin-top: 70px;
  }

  #about-bio {
    width: 75%;
  }

  .about-name {
    display: block;
    text-align: center;
    line-height: 80px;
  }


  .gif-ctr {
    max-width: 60%;
    height: auto;
    margin-bottom: 20px;
  }

  .header-content *,
  .header-black {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  #about-bio {
    padding: 2px;
    font-size: 12px;
    width: 95%;
  }

  .bio-text {
    margin: 0;
    padding: 15px;
  }

  .welcome-btn {
    font-size: 2rem;
    margin-bottom: 24px;
  }

  .welcome-btns {
    flex-direction: column;
    text-align: center;
  }

  #welcome {
    font-size: 3rem;
  }
}

@media screen and (max-width: 500px) {
  .welcome-msg {
    font-size: 2rem;
  }

}

/* //////////////////////////////////////////////////////////////////////Scroll Bar/////////// */
::-webkit-scrollbar {
  width: 0;
}

#scrollPath {
  position: fixed;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
}

#progressbar {
  position: fixed;
  top: 0;
  right: 0;
  width: 10px;
  z-index: 100;
  background: linear-gradient(to top, #008aff, #00ffe7);
  animation: animate 5s linear infinite;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

#progressbar:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #008aff, #00ffe7);
  filter: blur(10px);
}