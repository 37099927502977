.socials-ctr img {
    width: 50px;
    height: 50px;
    margin: 0 8px;
    transition: 200ms;
}

.socials-ctr img:hover {
    filter: invert(100%);
    transition: 200ms;
}

footer {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    width: 100vw;
    position: relative;
    min-height: 150px;
    color: white;
    position: relative;
}

.footer-content {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 2;
    height: 20px;
}

.socials-ctr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}